import type { JwtPayload } from "./JwtPayload.js";

export function parseJwt(token: string): JwtPayload {
	const [_, base64Url] = token.split(".");
	if (!base64Url) {
		throw new Error("Invalid JWT token");
	}
	const base64 = base64Url.replace(/-/gu, "+").replace(/_/gu, "/");
	const payload = decodeURIComponent(
		atob(base64)
			.split("")
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join(""),
	);
	return JSON.parse(payload) as JwtPayload;
}
