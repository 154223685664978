import {
	type AuthenticationResultType,
	CognitoIdentityProviderClient,
	ConfirmForgotPasswordCommand,
	type ConfirmSignUpCommandOutput,
	ForgotPasswordCommand,
	type ForgotPasswordCommandOutput,
	InitiateAuthCommand,
	RespondToAuthChallengeCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import {
	type CognitoIdentityCredentialProvider,
	fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import type { CognitoIdentityCredentials } from "@aws-sdk/credential-provider-cognito-identity/dist-types/fromCognitoIdentity.js";
import { parseJwt } from "../../jwt/parseJwt.js";
import { retryFailedToFetch } from "../../retryFailedToFetch.js";
import type { LoginResponse } from "./LoginResponse.js";
import type { SessionResponse } from "./SessionResponse.js";

export class CognitoClient {
	private readonly cognitoIdentityProviderClient: CognitoIdentityProviderClient;
	private cachedCredentials: CognitoIdentityCredentials | undefined;

	constructor(
		private readonly region: string,
		private readonly clientId: string,
		private readonly identityPoolId: string,
		private readonly userPoolId: string,
		private readonly allowUnauthenticated: boolean,
		private readonly createClients?: (credentialProvider: CognitoIdentityCredentialProvider) => void | Promise<void>,
		private readonly localStoragePrefix = `CognitoIdentityProvider-${userPoolId}`,
	) {
		this.cognitoIdentityProviderClient = new CognitoIdentityProviderClient({ region });
		this.cachedCredentials = this.getCachedCredentials();
		void this.createClients?.(this.createCredentialsProvider());
	}

	private getFromLocalStorage(key: string): string | null {
		// TODO Remove this after all clients have been updated
		const valueFromOldPrefix = localStorage.getItem(`CognitoIdentityProvider:${key}`);
		if (valueFromOldPrefix) {
			this.saveToLocalStorage(key, valueFromOldPrefix);
			localStorage.removeItem(`CognitoIdentityProvider:${key}`);
		}

		return localStorage.getItem(`${this.localStoragePrefix}:${key}`);
	}

	private saveToLocalStorage(key: string, value: string): void {
		// TODO Remove this after all clients have been updated
		localStorage.removeItem(`CognitoIdentityProvider:${key}`);

		localStorage.setItem(`${this.localStoragePrefix}:${key}`, value);
	}

	private removeFromLocalStorage(key: string): void {
		// TODO Remove this after all clients have been updated
		localStorage.removeItem(`CognitoIdentityProvider:${key}`);

		localStorage.removeItem(`${this.localStoragePrefix}:${key}`);
	}

	public async confirmNewPassword(
		session: string,
		username: string,
		newPassword: string,
	): Promise<ConfirmSignUpCommandOutput> {
		const command = new RespondToAuthChallengeCommand({
			ChallengeName: "NEW_PASSWORD_REQUIRED",
			ClientId: this.clientId,
			ChallengeResponses: {
				USERNAME: username,
				NEW_PASSWORD: newPassword,
			},
			Session: session,
		});
		return await retryFailedToFetch(() => this.cognitoIdentityProviderClient.send(command));
	}

	public async confirmResetPassword(
		username: string,
		password: string,
		confirmationCode: string,
	): Promise<ConfirmSignUpCommandOutput> {
		const command = new ConfirmForgotPasswordCommand({
			ConfirmationCode: confirmationCode,
			ClientId: this.clientId,
			Password: password,
			Username: username,
		});
		return await retryFailedToFetch(() => this.cognitoIdentityProviderClient.send(command));
	}

	public async forgotPassword(username: string): Promise<ForgotPasswordCommandOutput> {
		const command = new ForgotPasswordCommand({
			Username: username,
			ClientId: this.clientId,
		});
		return await retryFailedToFetch(() => this.cognitoIdentityProviderClient.send(command));
	}

	public async getSession(): Promise<SessionResponse | null> {
		try {
			const REFRESH_TOKEN = this.getFromLocalStorage("RefreshToken");
			if (!REFRESH_TOKEN) {
				return null;
			}

			const current = this.getFromLocalStorage("IdToken");
			if (current) {
				const parsed = parseJwt(current);
				if ((parsed.exp ?? 0) * 1000 > Date.now()) {
					return {
						idToken: current,
						payload: parsed,
					};
				}
			}

			const command = new InitiateAuthCommand({
				AuthFlow: "REFRESH_TOKEN",
				AuthParameters: { REFRESH_TOKEN },
				ClientId: this.clientId,
			});
			const { AuthenticationResult } = await retryFailedToFetch(() => this.cognitoIdentityProviderClient.send(command));

			if (AuthenticationResult) {
				await this.saveSession(AuthenticationResult);

				const idToken = AuthenticationResult.IdToken;
				if (!idToken) {
					return null;
				}

				return {
					idToken,
					payload: parseJwt(idToken),
				};
			}
		} catch (error) {
			await this.logout();
			window.location.reload();
			throw error;
		}

		return null;
	}

	public async login(username: string, password: string): Promise<LoginResponse> {
		this.deletedCachedCredentials();

		const command = new InitiateAuthCommand({
			AuthFlow: "USER_PASSWORD_AUTH", // TODO Maybe rework to SRP?
			AuthParameters: {
				USERNAME: username,
				PASSWORD: password,
			},
			ClientId: this.clientId,
		});

		try {
			const result = await retryFailedToFetch(() => this.cognitoIdentityProviderClient.send(command));
			if (result.AuthenticationResult) {
				await this.saveSession(result.AuthenticationResult);
				return { SignedIn: true };
			}
			return { SignedIn: false, Challenge: result.ChallengeName, Session: result.Session };
		} catch (error) {
			if (error instanceof Error) {
				if (error.name === "PasswordResetRequiredException") {
					return { SignedIn: false, Challenge: "PASSWORD_RESET" };
				}
			}

			throw error;
		}
	}

	public async logout(): Promise<void> {
		for (const key of ["IdToken", "AccessToken", "RefreshToken", "TokenType", "ExpiresIn"]) {
			this.removeFromLocalStorage(key);
		}
		this.deletedCachedCredentials();
		await this.createClients?.(this.createCredentialsProvider());
	}

	private waitForSession(): Promise<SessionResponse> {
		return new Promise((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			const interval = setInterval(async () => {
				const session = await this.getSession();
				if (session) {
					clearInterval(interval);
					resolve(session);
				}
			}, 1000);
		});
	}

	public createCredentialsProvider(): CognitoIdentityCredentialProvider {
		const refreshToken = this.getFromLocalStorage("RefreshToken");

		const provider = fromCognitoIdentityPool({
			clientConfig: { region: this.region },
			identityPoolId: this.identityPoolId,
			logins:
				refreshToken !== null || !this.allowUnauthenticated
					? {
							[`cognito-idp.eu-central-1.amazonaws.com/${this.userPoolId}`]: async () =>
								(await this.waitForSession()).idToken,
						}
					: undefined,
		});

		const credentialsProvider = (): CognitoIdentityCredentialProvider => {
			return async () => {
				try {
					if (
						this.cachedCredentials &&
						(!this.cachedCredentials.expiration || this.cachedCredentials.expiration.getTime() > Date.now())
					) {
						return this.cachedCredentials;
					}
					const credentials = await retryFailedToFetch(provider);
					this.cacheCredentials(credentials);
					return credentials;
				} catch (error) {
					await this.logout();
					window.location.reload();
					throw error;
				}
			};
		};

		return credentialsProvider();
	}

	private async saveSession(result: AuthenticationResultType): Promise<void> {
		for (const [key, value] of Object.entries(result)) {
			if (value !== undefined) {
				this.saveToLocalStorage(key, String(value));
			}
		}

		await this.createClients?.(this.createCredentialsProvider());
	}

	private getCachedCredentials(): CognitoIdentityCredentials | undefined {
		const cachedCredentials = this.getFromLocalStorage("CachedCredentials");
		if (cachedCredentials) {
			const parsedCredentials = JSON.parse(cachedCredentials) as Omit<CognitoIdentityCredentials, "expiration"> & {
				expiration: string;
			};
			return {
				...parsedCredentials,
				expiration: new Date(parsedCredentials.expiration),
			};
		}
		return undefined;
	}

	private cacheCredentials(credentials: CognitoIdentityCredentials): void {
		this.cachedCredentials = credentials;
		this.saveToLocalStorage("CachedCredentials", JSON.stringify(this.cachedCredentials));
	}

	private deletedCachedCredentials(): void {
		delete this.cachedCredentials;
		this.removeFromLocalStorage("CachedCredentials");
	}
}
