export const environmentKeys = [
	"ADMIN_SENTRY_DSN",
	"AWS_IDENTITY_POOL_ID",
	"AWS_REGION",
	"AWS_USER_POOL_CLIENT_ID",
	"AWS_USER_POOL_ID",
	"AZURE_CLIENT_ID",
	"AZURE_TENANT",
	"ENV",
	"ENV_DOMAIN",
	"MAIN_DOMAIN",
	"PRIVATE_GRAPHQL_ENDPOINT",
	"STORAGE_BUCKET",
	"STORAGE_URL",
	"UPLOAD_BUCKET",
	"UPLOAD_URL",
	"VERSION",
] as const;
