import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)": [4,[2]],
		"/(cognito)/confirm-recover": [34,[3]],
		"/(admin)/contact-forms": [5,[2]],
		"/(admin)/contact-forms/[id]": [6,[2]],
		"/(admin)/contacts": [7,[2]],
		"/(admin)/contacts/add": [8,[2]],
		"/(admin)/contacts/sort": [9,[2]],
		"/(admin)/contacts/[id]": [10,[2]],
		"/(cognito)/login": [35,[3]],
		"/(cognito)/new-password": [36,[3]],
		"/(admin)/news": [11,[2]],
		"/(admin)/news/add": [12,[2]],
		"/(admin)/news/[id]": [13,[2]],
		"/(admin)/oauth2": [14,[2]],
		"/(admin)/partners": [15,[2]],
		"/(admin)/partners/add": [16,[2]],
		"/(admin)/partners/sort": [17,[2]],
		"/(admin)/partners/[id]": [18,[2]],
		"/(admin)/position": [19,[2]],
		"/(admin)/position/add": [20,[2]],
		"/(admin)/position/[id]": [21,[2]],
		"/(admin)/products": [22,[2]],
		"/(admin)/products/add": [23,[2]],
		"/(admin)/products/sort": [24,[2]],
		"/(admin)/products/[id]": [25,[2]],
		"/(cognito)/recover": [37,[3]],
		"/(admin)/references": [26,[2]],
		"/(admin)/references/add": [27,[2]],
		"/(admin)/references/sort": [28,[2]],
		"/(admin)/references/[id]": [29,[2]],
		"/(admin)/settings": [30,[2]],
		"/(admin)/users": [31,[2]],
		"/(admin)/users/add": [32,[2]],
		"/(admin)/users/[id]": [33,[2]],
		"/[...path]": [38]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';