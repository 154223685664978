import { createClients } from "$lib/api/createClients";
import { environmentVariables } from "$lib/config/environmentVariables";
import { setupCzechMessagesForYup } from "$lib/yup/setupCzechMessagesForYup";
import { enableCache } from "@iconify/svelte";
import * as Sentry from "@sentry/browser";
import { consoleSandbox } from "@sentry/utils";
import type { HandleClientError } from "@sveltejs/kit";
import { AppSyncError } from "@core/utils/aws/appsync/AppSyncError";
import { failedToFetchModuleMessages } from "@core/utils/failedToFetchModuleMessages";

setupCzechMessagesForYup();
createClients();
enableCache("session");

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: environmentVariables.ADMIN_SENTRY_DSN,
		environment: environmentVariables.ENV,
		release: environmentVariables.VERSION,
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		sendDefaultPii: true,
		integrations: [
			// We handle errors ourselves in handleError.ts
			Sentry.globalHandlersIntegration({
				onunhandledrejection: false,
				onerror: false,
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				maskAllInputs: false,
				blockAllMedia: false,
			}),
			Sentry.httpClientIntegration(),
		],
		beforeSend(event, hint) {
			const exception = hint.originalException;

			if (exception instanceof AppSyncError) {
				event.fingerprint = ["{{ default }}", String(exception.name), String(exception.message)];
			}

			return event;
		},
	});
}

export const handleError = (input: Parameters<HandleClientError>[0]): ReturnType<HandleClientError> => {
	if (import.meta.env.PROD) {
		const isFailedToFetchError = failedToFetchModuleMessages.some(
			(message) => input.error instanceof Error && input.error.message.includes(message),
		);
		if (input.status !== 404 && !isFailedToFetchError) {
			Sentry.captureException(input.error, {
				mechanism: {
					type: "sveltekit",
					handled: false,
				},
			});
		}
	}

	consoleSandbox(() => {
		// eslint-disable-next-line no-console
		console.log(input.error);
	});

	if (input.error instanceof Error) {
		return {
			message: input.error.message,
		};
	}
};
